<template>
    <div class="row">
        <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none d-md-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>Fill in the Blanks
                </h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
             <h4 class="text-center mb-4 fancy"><span>Fill in the Blanks</span></h4>
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">You will hear a recording. Type the missing words in each blank.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Listening and writing</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 2 - 3 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>You will hear a recording and you need to type in the missing word as you hear the recording.</li>
                <li>You can also write down the missing words on your notepad and then once the audio is over, type all the words in the blanks.</li>
                <li>For typing answers, click on each gap and type in the missing word. You can also use the Tab button on the keyboard to move between gaps.</li>
                <li>In the 7 seconds before the recording begins, skim the text before the recording begins.</li>
            </ul>
            </template>
            <app-ListeningExtraInfo/>
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(questId != 0)}" v-if="selectedquestion">
            <h4>Fill in the Blanks</h4>
            <p class="questHeading">You will hear a recording. Type the missing words in each blank.</p>
            
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <div class="prepare" ><p>{{title}} </p><app-Timer ref="timer"/></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.question_id" :idName="'question_id'" :tableName="'tblListeningFillinBlankQ'"/>
            </div>
            </div>
            <!-- <p align="center"><strong>{{selectedquestion.title}}</strong></p> -->
            <div v-if="questionrecording" class="audio-container"><audio id="questionRec" :src="questionrecording" controls /></div>
            <p class="mt-3" v-html="selectedquestion.question_desc"></p>

            <div class="row mt-3">
                <div class="col-12 text-left mt-4" style="background: #001848;" v-if="answerSubmit">
                    <p style="padding-left: 0px !important; color: rgb(255, 255, 255);line-height:  unset !important;"><strong>Your Score:   <span class="pl-1">{{score_count}}</span>/{{actualAnswer.length}}</strong></p>
                </div>
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5">
                    <b-button class="mr-2 pl-4 pr-4" variant="outline-info" @click="submitanswer()">Submit</b-button>
                    <b-button variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <toggle-button @change="checkAnswer()" :width="75" :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Answer', unchecked: 'Answer'}"/>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button variant="outline-info" @click="$refs.intervalList.changeQuestion('next')" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 text-center mt-4" v-if="checkAnswerFlag">
                <h4 class="decorated" style="text-transform:capitalize;"><span>Answer</span></h4>
                    <div class="col-12 mb-2 mt-2 results text-left" >
                    <h5 style="text-transform:capitalize;">Transcript:- </h5>
                    <p style="display:block;padding-bottom: 0px !important;">
                        <ul >
                            <li v-for="item in actualAnswer" :key="item.answer_id" class="rightAnswer"><strong> {{item.blank_option}}</strong></li>
                        </ul>
                    </p>
                    </div>
                </div> -->
            </div>

             <!-- <div id="qlist2">
                <div :id="'dragitem'+item.blank_id+'-container'" class="drag-container" v-for="item in answerLists" :key="item.blank_id">
                <div :id="'dragitem'+item.blank_id" class="qitem2 answerDiv" >
                    {{item.blank_option}}
                </div>
                </div>
            </div> -->
            <!-- <br/>
            <div class="row">
                <div class="col-12  mb-2 mt-2 results" v-if="checkAnswerFlag == true">
                    <h6>Result:- </h6>
                    <p><strong>Your result is {{this.userAnswer.user_score}} out of {{correctAnswer.length}}</strong></p>
                </div>
                <div class="col-12" v-if="checkAnswerFlag == true" >
                    <ul >
                        <li v-for="item in correctAnswer" :key="item.blank_id" class="rightAnswer"><strong> {{item.blank_option}}</strong></li>
                    </ul>
                </div>
                <div class="col-12 text-center">
                    <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-5 prevQuestion">Prev Question</b-button>
                    <b-button variant="success" @click="submitanswer()">Find Answer & Marks</b-button>
                    <b-button @click="$refs.intervalList.changeQuestion('next')" class="ml-5 nextQuestion">Next Question</b-button>
                </div>
            </div> -->
        </div>
        <b-modal ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
             <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'question_id'"/></b-container>
        </b-modal>
        <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tblUserListeningFillinBlank'"/>
        </div> 
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import $ from "jquery";
import 'jquery-ui-dist/jquery-ui';
import Timer from '../reuseable/timer';
import IntervalList from '../reuseable/intervalList'
import QuestionStatus from '../reuseable/questStatus'
import DiscussionTab from '../reuseable/discussionTab'
import ListeningExtraInfo from '../reuseable/listeningInformation.vue'
//import LocalStorageService from '../../store/LocalStorageService'
export default {
    components:{
        'app-Timer':Timer,
 'app-IntervalList': IntervalList,
 'app-QuestionStatus':QuestionStatus,
 'app-DiscussionTab':DiscussionTab,
 'app-ListeningExtraInfo':ListeningExtraInfo
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            selectedquestion: null,
            answerLists:{
                type: Object,
                required: true
            },
            correctAnswer:null,
            questionrecording: null,
            title:'Prepare',
            userAnswerResponse:null,
            userAnswer:{
                user_id:0,
                question_id:0,
                answers:null,
                url:'',
                user_score: '',
                mock_test_id:0
            },
            checkAnswerFlag: false,
            score_count: 0,
            options:[],
            user_type:'Free',
            currentQuestion:0,
            actualAnswer:null,
            answerSubmit:false,
            searchKey:'',
            searchQuestionList:null,
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'listeningmodule/fetchQuestion',
            saveAnswer: 'listeningmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'listeningmodule/questions',
            answerResponse: 'listeningmodule/AnswerResponse'
        }),
        resetAll(){
            $('.element').each(function(index,item){
                    item.value = ''
                });
            this.checkAnswerFlag = false;
            this.score_count = 0;
            this.userAnswer={
                user_id:this.$ls.get('user').user_id,
                question_id:0,
                answers:null,
                url:'',
                user_score: '',
                mock_test_id:0
            }
            this.correctAnswer= null
            this.actualAnswer=null,
            this.answerSubmit=false
        },
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        redoQuest(){
            $('.showAnswer').remove()
            this.getQuestionDetail(this.currentQuestion)
        },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].question_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.question_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        getQuestionDetail(questionId){
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.removeClasses()
            this.currentQuestion=questionId
            this.resetAll();
            //console.log(questionId)
            this.userAnswer.question_id = questionId;
            this.selectedquestion = this.questionLists.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
            if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
            for(var index = 1; index <=10; index++)
            {
                this.selectedquestion.question_desc = this.selectedquestion.question_desc.replace('#blank' + index + '#', '<input type="text" class="element" style="width:150px;height:25px;display:inline-block;border:1px solid #ccc;margin:0 5px"/>')
            }
            
            ////console.log(this.selectedquestion)
            //this.questionservice('configlisteningblankq/blobId='+questionId).then(()=>{
                this.questionrecording = this.selectedquestion.blb //this.getQuestionList().data.blb
                ////console.log(this.selectedquestion)
                
                this.questionservice('configlisteningblankoptions/id='+questionId).then(()=>{
                this.answerLists = this.getQuestionList().data
                this.actualAnswer = this.answerLists
                });
                this.$nextTick(function(){
                this.$refs.discussionTab.updateQuestionId(questionId)
                this.$refs.timer.setTime(3)
                })
            //});
            
            // var binary= this.selectedquestion.question_recording;
            // //var data = 'data:audio/ogg;base64,'+binary;
            // this.questionrecording = binary;
            
        },
        async checkAnswer(){
            this.score_count=0
            $('.showAnswer').remove()
            if(this.checkAnswerFlag)
            {
            var vm = this;
            //this.checkAnswerFlag = true;
            vm.correctAnswer = vm.answerLists.filter(function(item){
                return item.correct_val_ind == 'T'
            });
            //$('.element').addClass('wrongSelection wrongAnswer')
            //console.log(vm.correctAnswer)
            $('.element').each(function(index,item){
                //console.log(vm.correctAnswer[index].blank_option)
                //console.log(item.value)
                if(vm.correctAnswer[index].blank_option.trim().toLowerCase() != item.value.trim().toLowerCase()){
                    $(item).addClass('wrongSelection wrongAnswer')
                    $(item).after(()=>{
                        return "<span class='showAnswer'> ( Answer:- " + vm.correctAnswer[index].blank_option.trim() + ")</span>"
                    })
                }
                else{
                    $(item).removeClass('wrongSelection wrongAnswer')
                    vm.score_count++
                }
            });

            this.userAnswer.user_score ='Your Score: '+vm.score_count+'/'+ vm.actualAnswer.length
            }
           
        },
        removeClasses(){
            var elements = document.getElementsByClassName('wrongSelection');
            while(elements.length > 0){
                elements[0].classList.remove("wrongSelection");
            }
            elements = document.getElementsByClassName('wrongAnswer');
            while(elements.length > 0){
                elements[0].classList.remove('wrongAnswer')
            }
            elements = document.getElementsByClassName('rightSection');
            while(elements.length > 0){
                elements[0].classList.remove("rightSection");
            }
            elements = document.getElementsByClassName('rightAnswer');
            while(elements.length > 0){
                elements[0].classList.remove('rightAnswer');
            }
            
        },
        _arrayBufferToBase64( buffer ) {
            var binary = '';
            var bytes = new Uint8Array( buffer );
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode( bytes[ i ] );
            }
            return window.btoa( binary );
        },
        timerEnd(){
               var aud = document.getElementById("questionRec");
               aud.play()
               this.$nextTick(function(){
                   this.title = 'Time'
                this.$refs.timer.setTime(0)
                })
            // aud.onended = function() {
            //     $('.ar-icon.ar-icon__lg').click()
            //     }
        },
        async submitanswer() {
            this.removeClasses()
            var vm = this;
            this.checkAnswerFlag=true
            await this.checkAnswer();
            this.answerSubmit = true
            $('.element').each(function(index,item){
                    //console.log(item)
                    const tempCt= {
                    answer_desc: item.value
                }
                vm.options.push(tempCt);
              
            });

            this.userAnswer.url = 'configlisteningblankanswers/createanswer';
            this.userAnswer.answers = this.options;
            this.userAnswer.mock_test_id=0
            var userdata = this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            //console.log(this.userAnswer);
            //if(this.userAnswer.length ==undefined) return
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                    vm.userAnswerResponse = this.answerResponse().data
                    //alert('Data has been saved successfully.');
                    //vm.resetAll();
                });
        },
    },
    beforeMount(){
        this.questionLists=(this.$ls.get('tblListeningFillinBlankQ')!=null)?JSON.parse(this.$ls.get('tblListeningFillinBlankQ')):null;
            console.log(this.questionLists)
            if(this.questionLists!=null){
                var userdata = this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            }
            else{
        this.questionservice('configlisteningblankq/getallquestions').then(()=>{
            this.questionLists = this.getQuestionList().data
            //var userdata = LocalStorageService.getUser()
            var userdata = this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            //console.log(this.questionLists)
        });
    }
    }
}
</script>
<style scoped>
p{
line-height: 25px;
}
#qlist2
{
    padding: 10px 19px;
border: 1px solid #ccc;
}
.drag-container
{
    display: inline;
}
.answerDiv
{
display: inline-block;
padding-top: 5px;
padding-right: 7px;
padding-bottom: 5px;
padding-left: 7px;
cursor: pointer;
min-height: 25px;
background: #eeeded;
margin: 0px 5px;
position: relative;
top: 0;
left: 0;
}
.element .answerDiv
{
background: none;
padding: 0 1px;
}
.text-center button{
    margin-right:30px
}
li.rightAnswer
{
    list-style: decimal;
}
.text-center button{
    margin-right:30px
}
.questiondesc p{
    line-height: 35px!important;
}
</style>