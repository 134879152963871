<template>
    <div class="row mt-5">
        <div class="col-sx-12 col-sm-12 col-md-6 col-lg-4">
            <video-embed :params="{showinfo: 0}" :src="url"></video-embed>
            
        </div>
        <div class="col-sx-12 col-sm-12 col-md-6 col-lg-7">
            <h5 class="mt-2 mb-2"><strong>{{title}}</strong></h5>
            <p class="para mb-2">
               {{ desc }}
            </p>
        </div>
    </div>
</template>
<script>
// import LazyYoutube from 'vue-lazytube'
export default {
    // components: {
    //     LazyYoutube,
    // },
    props:['showOverLap'],
    data(){
        return{
            url:"https://www.youtube.com/watch?v=VLMYkMzM42M",
            title:"How to prepare for PTE Exam | PTE Listening Module",
            desc:"PTE Listening is the final section of the test and will take approximately 45-60 minutes, depending on the combination of tasks you may be presented with. You will be listening to a short recording in each task, all of which will relate to academic subjects such as humanities, natural sciences and social sciences."
            
        }
    }
}
</script>
<style scoped>
div.row.mt-5{
    margin-top: 3rem!important;
}
</style>